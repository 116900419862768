
/* ##### Footer Area ##### */
.footer-bg{
    /*background: url(../img/bg-img/footer-bg.png) top center;*/
    background-size: cover
}
.footer-area {
    position: relative;
    z-index: 1;
    padding: 0;
}

.footer-content-area{
    padding: 200px 0 100px;
    background: linear-gradient(182deg, #2685ed 0%, #2f69ff 100%);
}
.footer-content-area.pt-100{
    padding-top: 100px;
}
.footer-content-area.spec{
    background: transparent !important;
}
.footer-content-area.demo{
    margin-top: 70px
}
.footer-logo{
    margin-bottom: 15px
}
.footer-logo a{
    color: #fff;
    font-size: 20px
}
.footer-logo img{
    width: 40px
}
.footer-side-thumbnail {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    height: 100%;
    background-size: cover;
    background-position: top center;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}

.copywrite_text > p {
    margin-bottom: 20px;
    color: #fff !important;
    font-size: 13px;
}

.copywrite_text > p > a {
    color: #fff;
}
.footer-social-info a{
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    text-align: center;
    background: #fff;
}

.footer-social-info a i {
    font-size: 14px;
    color: #5ba5fb;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}
.footer-content-area.spec p {
    color: #ccc;
}
.footer-social-info a i:hover {
    color: #4a7aec;
}

.contact_info_area .contact_info {
    text-align: left !important;
}

.contact_info_area .contact_info h5 {
    font-size: 14px;
}

.contact_info_area .contact_info p {
    margin-bottom: 0;
    font-size: 14px;
    color: #efebeb
}
.contact_info_area .contact_info a:hover p{
    color: #fff
}
