/* ##### 3.0 Wellcome Area CSS ##### */
.hero-section{
    position: relative;
    min-height: 700px;
    /*background: url('../../assets/img/bg-img/header2.jpg') no-repeat bottom center;*/
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-section.transparent{
    background: none;
}
.hero-section.main-page{
    /*background: url('../../assets/img/bg-img/header4.png') no-repeat bottom center;*/
    background-size: cover
}
.hero-section.demo{
    /*background: url('../../assets/img/bg-img/about-sec.jpg') no-repeat bottom center;*/
    background-size: cover
}
.hero-section.not{
    /*background: url('../../assets/img/bg-img/header5.jpg') no-repeat bottom center;*/
}

.hero-section.fuel{
    /*background: url('../../assets/img/bg-img/header2.png') no-repeat bottom center;*/
    background-size: cover;
    padding-top: 60px;
    padding-bottom: 565px;
}
.hero-section.ai1{
    /*background: url('../../assets/img/bg-img/ai1.png') no-repeat center;*/
}
.hero-section.ai2{
    /*background: url('../../assets/img/bg-img/ai2.png') no-repeat center;*/
    background-size: cover;
}
.hero-section.ai3{
    background: url('../../assets/img/bg-img/ai3.png') no-repeat top center;
    background-size: cover;
}

.hero-section.ai4{
    background: url('../../assets/img/bg-img/ai4.png') no-repeat top center;
    background-size: cover;
}
@media(max-width: 992px){
    .hero-section.ai3,
    .hero-section.ai4{
        background-position: top left;
    }
}
.hero-section.ai5{
    background: url('../../assets/img/bg-img/ai5.png') no-repeat top left;
    background-size: cover;
    padding-bottom: 100px;
}
.hero-section.overlayed{
    /*background: url('../../assets/img/bg-img/h-sec.jpg') no-repeat bottom center;*/
    background-size: cover;
}
.overlayed:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #13287e;
    opacity: .88;
    z-index:0;
}
.overlayed .video-icon{
    position: relative;
    left: 50%;
    margin-bottom: 30px;
}
.overlayed .video-icon:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 110px;
    width: 110px;
    background: #42d897;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    animation: pulse-anim 1500ms ease-out infinite;
}
@keyframes pulse-anim {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    }
}
@-webkit-keyframes pulse-anim {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    }
}

.hero-section.curved-section.not:before{
    content: "";
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    /*background: url(../../assets/img/core-img/demo-before.png) no-repeat;*/
    background-size: cover;
}
.hero-section.curved-section.not:after{
    display: none;
}
.subscribe-section {
    margin-bottom: 0px;
    background: rgba(255,255,255,.2);
    padding: 15px;
    display: inline-block;
    border-radius: 35px !important;
}
.subscribe-section .input-wrapper {
    position: relative;
    width: 100%;
    display: inline-block;
}
.subscribe-section i {
    font-size: 20px;
    color: #896eff;
    position: absolute;
    z-index: 1;
    top: 13px;
    left: 15px;
}
.subscribe-section input {
    padding: 10px 10px 10px 40px;
    position: relative;
    border: 2px solid #896eff;
    min-width: 350px;
    width: 100%;
    border-radius: 25px;
}
@media (min-width: 767px ){
    .hero-section.curved-section{
        padding-top: 50px
    }
}
@media (min-width: 992px ){
    .hero-section.curved-section{
        padding-top: 100px
    }
}
@media (max-width: 767px){
    .hero-section.curved-section img.curved{
        display: none;
    }
}
.hero-section.curved-section .special-head{
    padding-left: 0
}
.hero-section.curved-section .special-head:before{
    display: none;
}

.welcome_area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 900px !important;
}

.hero-content {
    width: 100%;
    height: 100%;
    background: rgba(72, 52, 212, 0.95);
    background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 10;
}
.hero-content.globe{
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.75));
}
.hero-section.demo h2{
    font-size: 45px
}
.robo{
    display: none;
}
@media (min-width: 1200px){
    .hero-section.demo img{
        position: relative;
        margin-top: 100px;
        margin-left: -100px
    }
    .overlayed h1{
        font-weight: 700 !important;
        font-size: 55px !important
    }
    .robo{
        display: block;
        position: absolute;
        width: 190px;
        height: 330px;
        top: -50px;
        left: -50px;
        animation: floating2 7s infinite;
        -webkit-animation: floating2 7s infinite;
    }
}
.hero-content.with-after{
    /*background: url('../../assets/img/svg/bg_hero.svg') no-repeat center right;*/
}
.hero-content.with-after-before{
    /*background-image: url(../../assets/img/svg/bg_hero1.svg),url(../../assets/img/svg/bg_hero2.svg);*/
    background-position: right top,left bottom;
    background-size: auto;
    background-repeat: no-repeat;
}
.hero-content.creative{
    /*background: url('../../assets/img/bg-img/header3.png') no-repeat center right;*/
    background-size: cover
}
.hero-content.pizza {
    /*background: url(../../assets/img/bg-img/banner-bg.jpg) no-repeat right top;*/
}
.hero-content.tringle{
    /*background: url('../../assets/img/bg-img/tringle.png') no-repeat center right;*/
}
.hero-content.scew{
    /*background: url('../../assets/img/bg-img/header2.png') no-repeat center top;*/
}
.hero-content.trans{
    /*background: url('../../assets/img/bg-img/trans.png') no-repeat right top;*/
}
.hero-content.transparent{
    background: transparent;
}
.hero-content.dark-blue{
    background: rgb(33, 0, 67, 0.9);
}
.hero-content .ico-counter{
    margin: 100px 7% 0;
}
@media (max-width: 992px){
    .hero-content .ico-counter,
    .hero-content .service-img-wrapper .image-box{
        margin-top: 0px !important
    }

}
.hero-content .service-img-wrapper .image-box .rings{
    position: absolute;
    top: 50%;
    z-index: -1;
    margin: 0 auto;
    left: 50% !important;
    width: 120%;
    transform: translate(-50%, -50%);
    animation: unset;
    -webkit-animation: unset;
}
.welcome-content {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}
.welcome-content.demo1{
    margin-top: 140px;
}

.promo-section {
    margin-bottom: 20px;
}
.special-head{
    color: #044afa !important;
    padding-left: 40px;
    font-size: 18px;
    position: relative;
    display: inline-block;
}
.special-head:before{
    content: '';
    background: #39f9f9;
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 2px;
}
.special-head.dark{
    color: #f8f517;
    font-weight: bold;
}
.special-head.dark:before{
    background: #fff
}
.integration-link {
    display: inline-block;
    vertical-align: top;
    position: relative;
    letter-spacing: .58px;
    font-size: 14px;
    line-height: 24px;
}

.integration-icon {
    margin: 0 5px 0 0;
}
.integration-icon .badge{
    background: #14cab1;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border-radius: 100px;
    padding: 6px 13px;
}
.integration-text {
    margin: 0 7px 0 0;
    color: yellow
}
.integration-link>* {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: top;
}

.main-ilustration{
    position: relative;
    /*background: url(../../assets/img/core-img/hero-bg.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: contain;
}
.main-ilustration-2{
    position: relative;
    /*background: url(../../assets/img/core-img/robot-1.png) no-repeat center bottom;*/
    height: 100vh;
    bottom: 0;
    background-size: contain;
}
.main-ilustration-3{
    position: relative;
    /*background: url(../../assets/img/core-img/robot-2.png) no-repeat center bottom;*/
    height: 100vh;
    bottom: 0;
    background-size: cover;
}
.main-ilustration-4{
    position: relative;
    /*background: url(../../assets/img/svg/head-bg-1.svg) no-repeat center 65%;*/
    height: 100vh;
    background-size: cover;
}
.main-ilustration-5{
    position: relative;
    /*background: url(../../assets/img/bg-img/header-1.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 80%;
}
.header-ilustration-1{
    min-width: 0px;
    margin-top: 60px;
    position: relative;
}
.header-ilustration-1 img{
    width: 100%;
    border-radius: 10px
}
.header-ilustration-1.big img{
    width: 105%
}
.has-shadow {
    -webkit-box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
    box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
    border-radius: 10px
}
.header-ilustration-1 .video-btn-container{
    position: absolute;
    width: 150px;
    height: 150px;
    /*background: url(../../assets/img/core-img/video-btn-bg.png) no-repeat center center;*/
    background-size: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header-ilustration-1 .video-btn-container .video-icon a{
    background: #fff;
    color: #2ea9fd;
}
.bub-right{
    /*background: url('../../assets/img/svg/bg_hero.svg') no-repeat center right;*/
}
.bub-left{
    /*background: url('../../assets/img/svg/benefits-bg.svg') no-repeat center left;*/
}
.hex-pat-1{
    background: url('../../assets/img/svg/hex-pattern.svg') no-repeat center left;
}
.hex-pat-2{
    position: relative;
}
.hex-pat-2:before{
    display: none;
}

@media (max-width: 992px){
    .header-ilustration-1{
        margin-top: 0
    }
    .bub-right{
        background-size: cover;
    }
    .bub-left{
        background-size: cover;
    }

}

.main-ilustration-6{
    position: relative;
    /*background: url(../../assets/img/core-img/about-1.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 100%;
}
.main-ilustration-7{
    position: relative;
    /*background: url(../../assets/img/core-img/faq.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 80%;
}
.main-ilustration-8{
    position: relative;
    /*background: url(../../assets/img/core-img/saas4.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 100%;
}
.main-ilustration-9{
    position: relative;
    /*background: url(../../assets/img/core-img/travel.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 100%;
}
.main-ilustration-10{
    position: relative;
    /*background: url(../../assets/img/core-img/pizza-header.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 80%;
}
@media (max-width: 992px){
    .main-ilustration-5{
        height: 65vh 
    }
    .mt-md-30{
        margin-top: 30px
    }
    .mb-md-30{
        margin-bottom: 30px
    }
}
@media (max-width: 1200px){
    .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 5%;
        display: inline-table;
    }
    .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: 27%;
    }
}

@media (max-width: 767px){
    .mt-sm-30{
        margin-top: 30px
    }
    .mt-sm-0{
        margin-top: 0px
    }
    .mb-sm-30{
        margin-bottom: 30px
    }
    .main-ilustration-6{
        height: 65vh 
    }
    .welcome-content{
        padding-right: 15px
    }
    .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 42%;
        display: inline-table;
    }
    .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: auto;
    }
    .welcome-small-wrapper{
        position: absolute;
        width: 38%;
    }
    .welcome-content-small{
        position: absolute;
        top: -30px;
    }
    .welcome-content.ill{
        margin-top: 120px !important
    }
    .header-ilustration-1 .video-btn-container{
        width: 150px;
        height: 150px
    }
}
@media (max-width: 480px){
    .main-ilustration-6,
    .header-ilustration-1{
       display: none;
    }
    .welcome-content {
        vertical-align: middle;
        margin-top: 100px !important

    }
    .main-ilustration-3{
        display: none;
    }
    .welcome-small-wrapper{
       display: none;
    }
    
}
.main-ilustration-2:before{
    content: '';
    position: absolute;
    width: 30%;
    height: 1000px;
    left: 50%;
    top: -30%;
    transform: rotate(21deg);
    background: rgba(0, 0, 128, 0.7);
}
.main-ilustration-3 .inovation{
    position: absolute;
    bottom: 10%;
    right: -15%;
    width: 40%;
    background: #0d003b;
    padding: 20px;
}
.inovation.num2{
    right: -57%;
}
.inovation h5{
    padding-left: 25px;
    position: relative;
}
.inovation h5:before{
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 15px;
    height: 2px;
    background: #25cbd3;
}
.inovation p{
    font-size: 12px;
    margin-bottom: 0;
}

@media (min-width: 1200px){
    .main-ilustration {
        min-width: 650px;
    }
    .hex-pat-2:before{
        position: absolute;
        right: 0;
        display: block;
        content: "";
        top: 20%;
        width: 400px;
        height: 400px;
        background: url('../../assets/img/svg/hex-pattern2.svg') no-repeat center right;
    }
}

.welcome-content h2 {
    font-size: 52px;
    font-weight: 500;
    margin-bottom: 20px;
}
.welcome-content h1 {
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 20px;
}
.welcome-content h1.artficial{
    font-size: 38px
}
.welcome-content p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
} 

p.artficial{
    padding-left: 20px;
    border-left: 1px solid #fff;
    position: relative;
}
p.artficial:before{
    content: '';
    position: absolute;
    left: -5px;
    top: -8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #fff
}
.welcome-content-small{
    margin-bottom: 50px
}
.welcome-content-small h4{
    margin-bottom: 20px
}
.welcome-content-small p.artficial{
    font-size: 12px
}
.welcome-video-area {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}

.welcome-video-area .welcome-thumb {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    border: 1px solid #25cbd3;
    border-radius: 15px;
    padding: 0px 30px 0;
}

.welcome-video-area .welcome-thumb img {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    position: relative;
    top: -30px
}

.video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #4834d4;
}

.video-btn {
    background-color: #4834d4;
    width: 70px;
    height: 70px;
    color: #fff;
    border-radius: 50%;
    line-height: 70px;
    padding: 0;
    text-align: center;
    min-width: 70px;
    font-size: 24px;
}

.video-btn:hover {
    background-color: #4834d4;
    color: #fff;
}


.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}
.welcome_area.video .desc{
    max-width: 60%;
    margin: 0 auto
}
@media (max-width: 767px) {
  .fullscreen-bg {
    /*background: url('video/video-frame.PNG') center center / cover no-repeat;*/
  }
   .welcome_area.video .desc{
        max-width: 80%;
        margin: 0 auto
    }
  .fullscreen-bg__video {
    display: none;
  }
}
.video-bg-illution{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #010111;
    z-index: 50;
    opacity: .7;
}
.small-feat{
    position: relative;
}
.small-feat .small-icon{
    max-width: 80px;
    margin-bottom: 20px;
}
.small-feat h5{
    color: #3581f3 !important;
    font-size: 16px;
}
.small-feat p{
    font-size: 13px;
}